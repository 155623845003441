var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',[_c('v-card',{class:[_vm.talhao.enabled ? 'green center' : 'card-disabled'],attrs:{"height":"176px"}},[_c('v-card-text',{staticClass:"float-right text-right d-block card-text-position pa-2"},[_c('div',{staticClass:"white--text font-weight-black"},[_vm._v(_vm._s(_vm.$t('area')))]),_c('div',{staticClass:"white--text font-weight-black"},[_vm._v(" "+_vm._s(_vm.areaTalhao)+" "+_vm._s(_vm.$unitMeasures['area'][_vm.$currentLanguage()])+" ")])])],1),_c('v-card',{attrs:{"height":"124px"}},[_c('v-card-text',{staticClass:"text-left pb-0 font-weight-black",attrs:{"flat":""}},[_c('v-row',{staticClass:"text-left pb-0 font-weight-black",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('p',{staticClass:"size-plot-name",attrs:{"title":_vm.talhao.nome}},[_vm._v(" "+_vm._s(_vm.talhao.nome)+" ")])]),(!_vm.talhao.enabled)?_c('v-col',{staticClass:"line-disable",attrs:{"cols":"6"}},[_c('span',{staticClass:"text-disable"},[_vm._v(_vm._s(_vm.$t('he_disabled')))])]):_vm._e()],1),_c('div',{staticClass:"caption text-wrap"},[_vm._v("lat: "+_vm._s(_vm.talhao.latitude))]),_c('div',{staticClass:"caption text-wrap"},[_vm._v("long: "+_vm._s(_vm.talhao.longitude))])],1),_c('v-card-actions',{class:[
          _vm.talhao.enable
            ? 'd-flex justify-space-around'
            : 'd-flex justify-end',
        ]},[(_vm.talhao.enabled)?_c('v-btn',{staticClass:"ml-1",class:[!_vm.talhao.ndviEnabled ? 'mr-auto' : ''],attrs:{"small":""},on:{"click":_vm.editPlot}},[(
              !_vm.talhao.ndviEnabled &&
              _vm.$vuetify.breakpoint.md &&
              !_vm.$vuetify.breakpoint.sm
            )?_c('span',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1):_c('span',[_vm._v(_vm._s(_vm.$t('editar')))])]):_vm._e(),(_vm.farm.enabled)?_c('v-btn',{staticClass:"button-enable-disable",attrs:{"small":""},on:{"click":function($event){return _vm.plotEnableDisable(_vm.talhao)}}},[(_vm.talhao.enabled)?_c('v-icon',{staticClass:"icon-enable"},[_vm._v("mdi-toggle-switch ")]):_c('v-icon',[_vm._v("mdi-toggle-switch-off-outline")]),(_vm.talhao.enabled)?_c('span',{staticClass:"black--text text-none pl-1"},[(
                !_vm.talhao.ndviEnabled &&
                !_vm.$vuetify.breakpoint.md &&
                !_vm.$vuetify.breakpoint.lg
              )?_c('span',[_vm._v(_vm._s(_vm.$t('disable')))]):_vm._e()]):_c('span',{staticClass:"black--text text-none pl-1"},[_vm._v(" "+_vm._s(_vm.$t('enable'))+" ")])],1):_vm._e(),(_vm.talhao.ndviEnabled && _vm.talhao.enabled)?_c('v-badge',{staticClass:"mr-auto",attrs:{"overlap":"","dot":"","color":"amber darken-2"}},[_c('v-btn',{staticClass:"ml-3",attrs:{"small":""},on:{"click":_vm.openAnalyze}},[(
                !_vm.talhao.ndviEnabled &&
                _vm.$vuetify.breakpoint.md &&
                !_vm.$vuetify.breakpoint.sm
              )?_c('span',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-map-marker-path")])],1):_c('span',[_vm._v("NDVI")])])],1):_vm._e(),(_vm.hasPermission(_vm.$p.plot.delete))?_c('v-btn',{attrs:{"color":"red","x-small":"","icon":"","text":""},on:{"click":_vm.confirmar}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()],1)],1)],1),(_vm.showConfirmationDialog)?_c('dialog-confirmation',{ref:"dialogConfirmation",attrs:{"dialog":_vm.showConfirmationDialog,"title":_vm.$t('talhoes.excluir_title'),"text":_vm.$t('talhoes.excluir_text')},on:{"agree":_vm.excluir}}):_vm._e(),_c('modal-ndvi',{ref:"modalNdvi"}),(_vm.plotRegisterOpen && _vm.talhao.id && _vm.accountsPlotsArea >= 0)?_c('plot-register',{attrs:{"plot":_vm.talhao,"plots":_vm.plots,"accounts-plots-area":_vm.accountsPlotsArea},on:{"refresh":_vm.refreshParent}}):_vm._e(),_c('action-dialog',{attrs:{"dialog":_vm.dialogEnableLimitExceeded,"text":_vm.$t('fazenda.enable.error'),"text-cancel":_vm.$t('cancelar'),"text-confirm":_vm.$t('get_in_touch'),"cancel":_vm.closeDialogEnableExceededConfirmation,"confirm":_vm.getInTouch,"color-cancel":"#8f8f8f","color-confirm":"#39af49"}}),_c('action-dialog',{attrs:{"dialog":_vm.dialogEnable,"text":_vm.$t('fazenda.enable.warning', [_vm.countEnabledAsc]),"text-cancel":_vm.$t('no'),"text-confirm":_vm.$t('yes'),"cancel":_vm.closeEnableConfirmation,"confirm":_vm.plotEnable,"color-cancel":"#8f8f8f","color-confirm":"#39af49"}}),_c('action-dialog',{attrs:{"dialog":_vm.dialogDisable,"text":_vm.$t('fazenda.disable.warning'),"text-cancel":_vm.$t('no'),"text-confirm":_vm.$t('yes'),"cancel":_vm.closeDisableConfirmation,"confirm":_vm.plotEnable,"color-cancel":"#8f8f8f","color-confirm":"#39af49"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }